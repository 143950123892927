import React from 'react';
import { Layout } from '../components/layout';
import { BrandingTemplate } from '../components/templates/branding';

const BrandingPage = () => (
  <Layout>
    <BrandingTemplate />
  </Layout>
);

export const Head = () => <title>Bermond Management</title>;

export default BrandingPage;
