import React from 'react';
import { StyledBottom, StyledContainer } from '../../../config/styled/shared';
import { WaveBranding } from '../../icons/WaveBranding';
import { BrandingLine } from '../../icons/BrandingLine';
import { Typography } from '../../../config/styled/typography';
import * as S from './styles';

const data = [
  {
    name: 'Logo design',
    content:
      'The logo is the centre of your brand.  Your logo will speak directly to your audience and let people know who and what you are without using words.',
  },
  {
    name: 'Social media',
    content:
      'We use social media to engage your audience through photos, videos, links and regular posts. Social media also creates a permanent marketing channel that leads to your website.',
  },
  {
    name: 'Web design',
    content:
      'Our specialists uses our skills and resources to create and maintain a website that reflects your brand and message. We design a full-featured platform that attracts visitors, helps you do business and creates extra publicity for your company.',
  },
  {
    name: 'Digital marketing',
    content:
      'We leverage all digital platforms through aggressive digital marketing and brand awareness. Digital marketing makes your brand visible to current customers and helps you convert potential customers into new ones.',
  },
  {
    name: 'Brand identity',
    content:
      "So, you've got a product, but you don't know how to sell it. Perhaps you need to start by selling your company. That's where branding starts. We can help you create a brand identity that is authentic and relevant to today's consumers. Our specialists can also help rebrand your company when the time comes for a paradigm shift.",
  },
];

export const BrandingTemplate = () => {
  return (
    <StyledBottom>
      <WaveBranding />
      <StyledContainer>
        <S.PageTop>
          <Typography.XXL as="h1" responsive css={{ color: '#ffffff', zIndex: 100 }}>
            Branding
          </Typography.XXL>
          <S.BrandingIconHolder>
            <BrandingLine />
          </S.BrandingIconHolder>
        </S.PageTop>
        <S.Text>
          Today, your brand has unlimited possibilities to promote where you want to go and expand your market reach.
          Our branding services use technology to fine-tune your company image. Branding services include:
        </S.Text>
        <S.List>
          {data.map((i, index) => (
            <S.ListItem key={index}>
              <S.Name>{i.name}</S.Name>
              <S.Content>{i.content}</S.Content>
            </S.ListItem>
          ))}
        </S.List>
      </StyledContainer>
    </StyledBottom>
  );
};
