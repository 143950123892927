import styled from '@emotion/styled';
import { mq } from '../../../config/styled/media';

export const PageTop = styled.div`
  position: relative;
  display: inline-block;
  margin: 78px 0 99px 0;
  ${mq.lt.md} {
    margin: 24px 0 0 0;
  }
`;

export const BrandingIconHolder = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  width: 600px;
  transform: translate(75%, -50%);
  ${mq.lt.md} {
    width: 180px;
    right: -10%;
  }
  svg > path {
    stroke-width: 19;
  }
`;

export const Text = styled.p`
  font-size: 3.6rem;
  line-height: 4.3rem;
  color: #ffffff;
  margin: 42px 0;
  font-weight: 400;
  ${mq.lt.lg} {
    font-size: 3rem;
    line-height: 3.4rem;
  }
  ${mq.lt.md} {
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 24px 0;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const ListItem = styled.li`
  color: #ffffff;
  padding: 42px 0 63px 0;
  display: flex;
  position: relative;
  &:not(:last-child) {
    border-bottom: 2px solid #fbfbfb;
  }
  ${mq.lt.md} {
    padding: 24px 0;
    flex-direction: column;
    gap: 24px;
    border-bottom: 0 !important;
    &::after {
      content: '';
      height: 2px;
      background-color: #fbfbfb;
      width: 100vw;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }
`;

export const Name = styled.span`
  font-size: 5.6rem;
  line-height: 6.7rem;
  flex-basis: 50%;
  font-weight: 400;
  ${mq.lt.lg} {
    font-size: 4rem;
    line-height: 4.4rem;
  }
  ${mq.lt.md} {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }
`;

export const Content = styled.span`
  font-size: 3.6rem;
  line-height: 4.3rem;
  flex-basis: 50%;
  font-weight: 400;
  ${mq.lt.lg} {
    font-size: 3rem;
    line-height: 3.3rem;
  }
  ${mq.lt.md} {
    font-size: 2rem;
    line-height: 2.4rem;
  }
`;
